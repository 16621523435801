import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../Layout/Layout";
import Disqus from "../components/Disqus/Disqus";
import ArticleTags from "../components/ArticleTags/ArticleTags";
import config from "../../data/SiteConfig";

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <div>
            <header>
                <div className="postTitle">{post.title}</div>
                <p>Last Modified: <span>{post.date}</span></p>
                <div>
                  <ArticleTags tags={post.tags}/>
                </div>
            </header>
            <div className="markdownWrapper" dangerouslySetInnerHTML={{ __html: postNode.html }} />
            <Disqus postNode={postNode} />
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;
